import BarMops1 from "../Images/Barmops3.png";
import BarMops2 from "../Images/Barmops4.png";
import BarMops3 from "../Images/Barmops5.png";
import BarMops4 from "../Images/Barmops6.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function scrollToTop() {
    window.scrollTo(0, 0); // Scroll to the top of the page
}

export default function BarMops() {
    return (
        <section className="py-5 mt-5" id="about">
            <Helmet>
                <meta name="keywords" content="Wholesale Bar Mops, Commercial Cleaning Supplies, Bulk Orders" />
            </Helmet>
            <div className="container">
                <div className="row mt-5">
                    <div className="col-sm-5 d-flex justify-content-center align-items-center items">
                        <div className="row">
                            <div className="col-12 mb-3">
                                <div className="zoom img-border">
                                    <img className="object-fit-fill about-img" src={BarMops1} alt="About Us" />
                                </div>
                            </div>
                            <div className="col-12 mb-5">
                                <div id="myModal" className="modal">
                                    <span className="close">&times;</span>
                                    <div className="modal-content">
                                        <img id="modalImg" src="" alt="Modal" />
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center mx-auto mt-3">
                                    <div className="col-3">
                                        <img className="gallery-img" src={BarMops2} alt="BarMops" />
                                    </div>
                                    <div className="col-3">
                                        <img className="gallery-img" src={BarMops3} alt="BarMops" />
                                    </div>
                                    <div className="col-3">
                                        <img className="gallery-img" src={BarMops4} alt="BarMops" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-7 d-flex justify-content-center flex-column mx-auto text-center align-items-center">
                        <h3 className="font-weight-bold about-title">BarMops</h3>
                        <div className="sectionUnderline mx-auto"></div>
                        <p className="mt-4 about-text">Ziftex International bar mops are crafted to endure rigorous commercial and institutional use. Ideal for various cleaning tasks, they are soft yet durable, capable of withstanding multiple washes while maintaining high absorbency. Trust Ziftex for reliable and long-lasting cleaning solutions.</p>
                        <Link className="product-button mt-2" to="/contact" onClick={scrollToTop}>Inquire Now</Link>
                    </div>
                </div>
            </div>

            <Helmet>
                <script src="../js/Modal.js" />
            </Helmet>
        </section>
    );
}
