import DenimJackets1 from "../Images/DENIM JACKETS 2.png";
import DenimJackets2 from "../Images/Denim Jackets3.png";
import DenimJackets3 from "../Images/Denim Jackets4.png";
import DenimJackets4 from "../Images/denim jacket6.png";


import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function scrollToTop() {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }

export default function DenimJackets() {
    return (
        <section className="py-5 mt-5" id="about">
            <Helmet>
                <meta name="keywords" content="Wholesale Denim Jackets, Classic & Trendy Styles, Bulk Export" />
            </Helmet>
       
            <div className="container">
    <div className="row mt-5">
        <div className="col-sm-5 d-flex justify-content-center align-items-center items">
            <div className="row">
                <div className="col-12 mb-3">
                    <div className="zoom img-border">
                        <img className="object-fit-fill about-img" src={DenimJackets1} alt="Denim Jackets" />
                    </div>
                </div>
                <div className="col-12 mb-5">
                    <div id="myModal" className="modal">
                        <span className="close">&times;</span>
                        <div className="modal-content">
                            <img id="modalImg" src="" alt="Modal" />
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mx-auto mt-3">
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={DenimJackets2} alt="Denim Jackets" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={DenimJackets3} alt="Denim Jackets" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={DenimJackets4} alt="Denim Jackets" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-sm-7 d-flex justify-content-center flex-column mx-auto text-center align-items-center">
            <h3 className="font-weight-bold about-title">Denim Jackets</h3>
            <div className="sectionUnderline mx-auto"></div>
            <p className="mt-4 about-text">Ziftex International offers a range of stylish denim jackets crafted with quality materials and meticulous attention to detail, providing both style and durability for any occasion. Elevate your outerwear with our premium denim jacket collection.</p>
            <Link className="product-button mt-2" to="/contact" onClick={scrollToTop}>Inquire Now</Link>
        </div>
    </div>
</div>
<div className="container">
                <div className="row mt-5">
                    <div className="col-6 d-flex justify-content-center align-items-center items">
                        <div className="zoom img-border">
                            <img className="object-fit-fill" src={DenimJackets1} alt="Denim Jackets" />
                        </div>
                    </div>
                    <div className="col-6 d-flex justify-content-center flex-column mx-auto text-center align-items-center">
                        <h3 className="">Denim Jackets</h3>
                        <div className="sectionUnderline mx-auto"></div>
                        <p className="mt-4 about-text text-justify">Ziftex International offers a range of stylish denim jackets crafted with quality materials and meticulous attention to detail, providing both style and durability for any occasion. Elevate your outerwear with our premium denim jacket collection.</p>
                        <Link className="product-button mt-2" to="/contact" onClick={scrollToTop}>Inquire Now</Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div id="myModal" className="modal">
                            <span className="close">&times;</span>
                            <div className="modal-content">
                                <img id="modalImg" src="" alt="Modal" />
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mx-auto mt-3">
                            <div className="col-3">
                                <img className="gallery-img" src={DenimJackets2} alt="Denim Jackets" />
                            </div>
                            <div className="col-3">
                                <img className="gallery-img" src={DenimJackets3} alt="Denim Jackets" />
                            </div>
                            <div className="col-3">
                                <img className="gallery-img" src={DenimJackets4} alt="Denim Jackets" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Helmet>
                <script src="../js/Modal.js" />
            </Helmet>
        </section>
    );
}