import YellowDusterandMicroFiber1 from "../Images/microfibre4.png";
import YellowDusterandMicroFiber2 from "../Images/microfibre2.png";
import YellowDusterandMicroFiber3 from "../Images/yellow duster 2.png";
import YellowDusterandMicroFiber4 from "../Images/yellow duster 4.png";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function scrollToTop() {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }


export default function YellowDusterandMicroFiber() {
    return (
        <section className="py-5 mt-5" id="about">
            <Helmet>
                <meta name="keywords" content="Wholesale Dusting Cloths & Microfiber Towels, Cleaning Supplies, Bulk Export" />
            </Helmet>
            <div className="container">
    <div className="row mt-5">
        <div className="col-sm-5 d-flex justify-content-center align-items-center items">
            <div className="row">
                <div className="col-12 mb-3">
                    <div className="zoom img-border">
                        <img className="object-fit-fill about-img" src={YellowDusterandMicroFiber1} alt="Yellow Duster and Micro Fiber" />
                    </div>
                </div>
                <div className="col-12 mb-5">
                    <div id="myModal" className="modal">
                        <span className="close">&times;</span>
                        <div className="modal-content">
                            <img id="modalImg" src="" alt="Modal" />
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mx-auto mt-3">
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={YellowDusterandMicroFiber2} alt="Yellow Duster and Micro Fiber" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={YellowDusterandMicroFiber3} alt="Yellow Duster and Micro Fiber" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={YellowDusterandMicroFiber4} alt="Yellow Duster and Micro Fiber" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-sm-7 d-flex justify-content-center flex-column mx-auto text-center align-items-center">
            <h3 className="font-weight-bold about-title">Yellow Duster & Micro Fiber</h3>
            <div className="sectionUnderline mx-auto"></div>
            <p className="mt-4 about-text text-justify">Ziftex International microfiber towels and yellow dusters offer superior absorbency and softness, high strength, and a soft touch. With fast bright color, machine washability, and easy cleaning, they ensure long-lasting durability and anti-bacterial properties. Perfect for repetitive use, they are a cost-effective cleaning solution.</p>
            <Link className="product-button mt-2" to="/contact" onClick={scrollToTop}>Inquire Now</Link>
        </div>
    </div>
</div>

            <Helmet>
                <script src="../js/Modal.js" />
            </Helmet>
        </section>
    );
}