import BeddingLinens1 from "../Images/Bedding Linens3.png";
import BeddingLinens2 from "../Images/Bedding Linens4.png";
import BeddingLinens3 from "../Images/Bedding Linens5.png";
import BeddingLinens4 from "../Images/Bedding Linens6.png";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function scrollToTop() {
    window.scrollTo(0, 0); // Scroll to the top of the page
}

export default function BeddingLinens() {
    return (
        <section className="py-5 mt-5" id="about">
            <Helmet>
                <meta name="keywords" content="Wholesale Bed Linens, Hotel Quality, Global Supplier" />
            </Helmet>

            <div className="container">
                <div className="row mt-5">
                    <div className="col-sm-5 d-flex justify-content-center align-items-center items">
                        <div className="row">
                            <div className="col-12 mb-3">
                                <div className="zoom img-border">
                                    <img className="object-fit-fill about-img" src={BeddingLinens1} alt="Bedding Linens" />
                                </div>
                            </div>
                            <div className="col-12 mb-5">
                                <div id="myModal" className="modal">
                                    <span className="close">&times;</span>
                                    <div className="modal-content">
                                        <img id="modalImg" src="" alt="Modal" />
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center mx-auto mt-3">
                                    <div className="col-3 gallery-col">
                                        <img className="gallery-img" src={BeddingLinens2} alt="Bedding Linens" />
                                    </div>
                                    <div className="col-3 gallery-col">
                                        <img className="gallery-img" src={BeddingLinens3} alt="Bedding Linens" />
                                    </div>
                                    <div className="col-3 gallery-col">
                                        <img className="gallery-img" src={BeddingLinens4} alt="Bedding Linens" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-7 d-flex justify-content-center flex-column mx-auto text-center align-items-center">
                        <h3 className="font-weight-bold about-title">Bedding Linens</h3>
                        <div className="sectionUnderline mx-auto"></div>
                        <p className="mt-4 about-text">Ziftex International specializes in providing premium quality bed linen, meticulously tailored for the hospitality industry's unique requirements. Our sateen and percale fabrics are renowned for their dense construction, ensuring durability even through industrial washing, while preserving the luxurious feel of the product. Partner with Ziftex for impeccable quality and reliability in every linen purchase, designed to elevate the guest experience in your establishment.</p>
                        <Link className="product-button mt-2" to="/contact" onClick={scrollToTop}>Inquire Now</Link>
                    </div>
                </div>
            </div>

            <Helmet>
                <script src="../js/Modal.js" />
            </Helmet>
        </section>
    );
}
