import React from "react";
import Img from "../Images/ZI LOGO1 HD.png";
import { Link } from "react-router-dom";

function handleEmailClick() {
    const email = 'info@ziftexinternational.com';
    const mailToLink = `mailto:${email}`;

    // Open default email client
    window.location.href = mailToLink;
}

function handleEmailClickSales() {
    const email = 'sales@ziftexinternational.com';
    const mailToLink = `mailto:${email}`;

    // Open default email client
    window.location.href = mailToLink;
}
function scrollToTop() {
    window.scrollTo(0, 0); // Scroll to the top of the page
}
export default function Footer() {
    return (
        <div className="">

            <div className="section scrollspy" id="contact">

                <div className="container-fluid p-3 contact-container">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-4 text-center">
                                <h5 className="footer-title">ZIFTEX INTERNATIONAL</h5>

                                <hr />
                                <div className="">
                                    <Link to="/"> <img src={Img} width="70px" alt="logo" onClick={scrollToTop} /></Link>
                                </div>
                                <p>Ziftex International is a premier buying and outsourcing agency based in Pakistan. We specialize in exporting high-quality products and Committed to excellence, we offer competitive pricing to meet the diverse needs of our global clients.</p>
                            </div>
                            <div className="col-sm-2 text-center footer-categories">
                                <h5 className="footer-title">CATEGORIES</h5>
                                <hr />
                                <p>
                                    <Link to="/HealthCare"
                                        className="nav-options-footer text-decoration-none" onClick={scrollToTop}>Health Care</Link><br /><br />
                                    <Link to="/Hospitality" className="nav-options-footer text-decoration-none" onClick={scrollToTop}>Hospitality</Link><br /><br />
                                    <Link to="/Commercial" className="nav-options-footer text-decoration-none" onClick={scrollToTop}>Commercial</Link><br /><br />
                                    <Link to="/Bedandbath" className="nav-options-footer text-decoration-none" onClick={scrollToTop}>Bed & Bath</Link><br /><br />
                                    <Link to="/Garments" className="nav-options-footer text-decoration-none" onClick={scrollToTop}>Garments</Link><br /><br />
                                </p>
                            </div>
                            <div className="col-sm-4 text-center mx-auto footer-contact">
                                <h5 className="footer-title">CONTACT</h5>
                                <hr />

                                <p className=""><b>Phone:</b> <br />00923122003321</p>
                                <p className="" onClick={handleEmailClick}><b>Email:</b><br />  info@ziftexinternational.com</p>
                                <p className="" onClick={handleEmailClickSales}>sales@ziftexinternational.com</p>
                                <p className=""><b>Location:</b><br />  Plot No# R-18, Sector 14A, Karachi-Pakistan.</p>

                            </div>
                            <div className="col-sm-2 text-center">
                                <h5 className="footer-title">FOLLOW US</h5>
                                <hr />
                                <div className="divider"></div>
                                <p>
                                    <div className="row">
                                        <div className="col-sm-9 flex-column mx-auto text-center">
                                            {<p className="lead"></p>}
                                            <div className="btn_container d-flex flex-row mx-auto justify-content-center">
                                                <a href="https://www.facebook.com/ZiftexIntl2000" target="_blank" rel="noopener noreferrer">
                                                    <i className="fab fa-facebook mr-4 fa-1x" style={{ color: 'black' }}></i></a>
                                                <a href="https://x.com/ZiftexIntl2000" target="_blank" rel="noopener noreferrer">
                                                    <i className="fab fa-twitter fa-1x mr-4" style={{ color: 'black' }}></i></a>
                                                <a href="https://wa.me/+923122003321" target="_blank" rel="noopener noreferrer">
                                                    <i className="fab fa-whatsapp mr-4" style={{ color: 'black' }}></i></a>
                                                <a href="https://www.linkedin.com/company/ziftex-international" target="_blank" rel="noopener noreferrer">
                                                    <i className="fab fa-linkedin fa-1x mr-4" style={{ color: 'black' }}></i></a>

                                            </div>
                                        </div>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <footer className="page-footer p-3">
                <div className="footer-copyright">
                    <div className="container d-flex justify-content-center">
                        ©2024 Ziftex International


                    </div>
                </div>

            </footer>
        </div>
    );
}
