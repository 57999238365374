import { Helmet } from "react-helmet";


export default function Garments() {
    return (

        <section className="py-5 mt-5">
             <Helmet>
                <meta name="keywords" content="Custom T-Shirts, Denim Jeans, Shorts, Custom Clothing, Casual Wear, Night wears" />
            </Helmet>
              <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="featured-project-wrapper">
                            <div className="row mt-5">
                                <div className="col-lg-6">
                                    <div className="section-title-area">
                                        <h6 className="subtitle">FEATURED PRODUCTS</h6>
                                        <h2 className="font-weight-bold">Garments</h2>
                                        <div className="sectionUnderline-feature mt-3"></div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="">
                                    <p className="">Established in the year 2000, Ziftex International stands out as a renowned buying and outsourcing agency, proudly managing its operations in Pakistan. With a track record of exporting top-tier products to the U.S.A, Canada, Europe, and the Middle East, we have built a reputation for excellence and reliability in the global market.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="roadmap" className="container-fluid  py-5">
        <div className="container title ">
           
            <div className="row mt-5">
                <div className="col-md-12">
                    <div className="main-timeline">
                        <div className="timeline left wow fadeInLeft">
                            <div className="card">
                                <div className="card-body">
                                    <h6>T-SHIRTS</h6>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="timeline right wow fadeInRight">
                            <div className="card">
                                <div className="card-body">
                                    <h6>SHORTS & TROUSERS</h6>
                                  
                                </div>
                            </div>
                        </div>


                        <div className="timeline left wow fadeInLeft">
                            <div className="card">
                                <div className="card-body">
                                    <h6>DENIM JEANS</h6>
                                   
                                </div>
                            </div>
                        </div>
                        <div className="timeline right wow fadeInRight">
                            <div className="card">
                                <div className="card-body">
                                    <h6>DENIM JACKETS</h6>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="timeline left wow fadeInRight">
                            <div className="card">
                                <div className="card-body">
                                    <h6>NIGHT WEARS</h6>
                                    
                                </div>
                            </div>
                        </div>
                    
          
                    </div>
                </div>
            </div>
       
        </div>
    </div>
        </section>


    );
}
