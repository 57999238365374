import ThermalBlanket1 from "../Images/Thermal Blankets1.png";
import ThermalBlanket2 from "../Images/Thermal Blankets2.png";
import ThermalBlanket3 from "../Images/Thermal Blankets3.png";
import ThermalBlanket4 from "../Images/Thermal Blankets4.png";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function scrollToTop() {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }

export default function ThermalBlankets() {
    return (
        <section className="py-5 mt-5" id="about">
            <Helmet>
                <meta name="keywords" content="Wholesale Thermal Blankets, Emergency Blankets, Disposable & Reusable, supplier" />
            </Helmet>
            <div className="container">
    <div className="row mt-5">
        <div className="col-sm-5 d-flex justify-content-center align-items-center items">
            <div className="row">
                <div className="col-12 mb-3">
                    <div className="zoom img-border">
                        <img className="object-fit-fill about-img" src={ThermalBlanket1} alt="Thermal Blanket" />
                    </div>
                </div>
                <div className="col-12 mb-5">
                    <div id="myModal" className="modal">
                        <span className="close">&times;</span>
                        <div className="modal-content">
                            <img id="modalImg" src="" alt="Modal" />
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mx-auto mt-3">
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={ThermalBlanket2} alt="Thermal Blanket" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={ThermalBlanket3} alt="Thermal Blanket" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={ThermalBlanket4} alt="Thermal Blanket" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-sm-7 d-flex justify-content-center flex-column mx-auto text-center align-items-center">
            <h3 className="font-weight-bold about-title">Thermal Blankets</h3>
            <div className="sectionUnderline mx-auto"></div>
            <p className="mt-4 about-text">Ziftex International thermal blankets offer breathable warmth, providing just the right amount of coziness to ward off the chill without inducing sweat. Perfect for use on their own in summer, for layering in winter, and for seamless transition between seasons.</p>
            <Link className="product-button mt-2" to="/contact" onClick={scrollToTop}>Inquire Now</Link>
        </div>
    </div>
</div>

            <Helmet>
                <script src="../js/Modal.js" />
            </Helmet>
        </section>
    );
}
