import AboutUs1 from "../Images/About5.png";
import AboutUs2 from "../Images/About1.jpg";
import AboutUs3 from "../Images/About2.jpg";
import AboutUs4 from "../Images/About3.jpg";


import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function scrollToTop() {
    window.scrollTo(0, 0); // Scroll to the top of the page
}

export default function AboutUs() {
    return (
        <section className="py-5 mt-5" id="about">
            <Helmet>
                <meta name="keywords" content="Pakistan Textile Sourcing | Wholesale Towels, Bed Sheets, Shop Towels" />
            </Helmet>
            <div className="container">
                <div className="row mt-5">
                    <div className="col-sm-5 d-flex justify-content-center align-items-center items">
                        <div className="row">
                            <div className="col-12 mb-3">
                                <div className="zoom img-border">
                                    <img className="object-fit-fill about-img" src={AboutUs1} alt="About Us" />
                                </div>
                            </div>
                            <div className="col-12 mb-5">
                                <div id="myModal" className="modal">
                                    <span className="close">&times;</span>
                                    <div className="modal-content">
                                        <img id="modalImg" src="" alt="Modal" />
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center mx-auto mt-3">
                                    <div className="col-3 gallery-col">
                                        <img className="gallery-img" src={AboutUs2} alt="About Us" />
                                    </div>
                                    <div className="col-3 gallery-col">
                                        <img className="gallery-img" src={AboutUs3} alt="About Us" />
                                    </div>
                                    <div className="col-3 gallery-col">
                                        <img className="gallery-img" src={AboutUs4} alt="About Us" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-7 d-flex justify-content-center flex-column mx-auto text-center align-items-center">
                        <h3 className="font-weight-bold about-title">About The Ziftex International</h3>
                        <div className="sectionUnderline mx-auto"></div>
                        <p className="mt-4 about-text">Established in the year 2000, Ziftex International stands out as a renowned buying and outsourcing agency, proudly managing its operations in Pakistan. With a track record of exporting top-tier textile products to the U.S.A, Canada, Europe, and the Middle East, Ziftex International distinguishes itself through its exclusive outsourcing units. This unique advantage empowers the company to provide highly competitive prices and tailor-made products for its esteemed clients. Demonstrating a steadfast commitment to reducing operational costs and aspiring for greater prominence in global markets, Ziftex International offers a diverse range of dedicated services to meet the evolving needs of its customers.</p>
                        <Link className="product-button mt-2" to="/contact" onClick={scrollToTop}>Inquire Now</Link>
                    </div>
                </div>

            </div>
            <Helmet>
                <script src="../js/Modal.js" />
            </Helmet>
        </section>
    );
}