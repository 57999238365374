import { Helmet } from "react-helmet";


export default function BodySection() {
    return (
      <section className="mb-5 p-5">
        <Helmet>
                <meta name="keywords" content="Textile Production, Textile Expertise, Textile Vision, Terry Towels, Bar Mops, Wash Cloths, Kitchen Towels, Napkins, Shop Towels, Patient Gowns, Surgical Towels, Bed Sheets, Fitted Sheets, Pillow Covers, Blankets" />
            </Helmet>
            <div className="col-md-8 mx-auto mt-4 home p-5 font-weight-bold">
            <p><i>Ziftex International specializes in a diverse range of textile offerings tailored to meet the demands of the Hospitality, Healthcare, and Industrial sectors. Our product portfolio includes Terry Towels, Bar Mops, Wash Cloths, Kitchen Towels, Napkins, Shop Towels, Patient Gowns, Surgical Towels (Lint-Free), Bed Sheets, Fitted Sheets, Pillow Covers, and Blankets. Positioned as a leader in exports within the Pakistani market, our commitment is unwavering in delivering top-notch products and services to our customers, featuring unique, custom-made solutions. With a proud 24-year history in textile outsourcing and export, we strive to not only maintain but also elevate our standards, aiming for business expansion across various global regions.</i></p>
            </div>
      </section>
   
  
    );
}

