import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import emailjs from 'emailjs-com';  // Make sure to install emailjs-com via npm

const Contact = () => {
    useEffect(() => {
        // Initialize emailjs
        (function () {
            emailjs.init("JXvNiJ92h9ARHUGrk");
        })();

        // Set up form submission handler
        const form = document.getElementById('contactform');
        if (form) {
            form.addEventListener('submit', function (event) {
                event.preventDefault();
                emailjs.sendForm('service_y7r5w4j', 'template_e0fguoi', this)
                    .then(() => {
                        alert('Success! Your message has been sent.', 'alert-success');
                        console.log('SUCCESS!');
                        var formElements = this.elements;
                        for (var i = 0; i < formElements.length; i++) {
                            var element = formElements[i];
                            if (element.type !== 'hidden') {
                                element.value = '';
                            }
                        }
                    }, (error) => {
                        console.log('FAILED...', error);
                    });
            });
        }
    }, []);

    const handleEmailClick = () => {
        const email = 'info@ziftexinternational.com';
        const mailToLink = `mailto:${email}`;
        window.location.href = mailToLink;
    };

    return (
        <section>
            <div>
                <section className="advantage py-5 mt-5" id="advantage">
                    <Helmet>
                        <meta name="keywords" content="Custom Textile Outsourcing | Towels, Bed Sheets, Shop Towels, Garments" />
                    </Helmet>
                    <div className="container-fluid py-5">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="row mx-auto justify-content-left">
                                    <div className="col-sm-2">
                                        <i className="fa-solid fa-location-crosshairs fa-3x"></i>
                                    </div>
                                    <div className="col-sm-10">
                                        <h5 className="text-start">Location</h5>
                                        <p className="text-start">Plot No# R-18, Sector 14A, Karachi-Pakistan.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="row d-flex mx-auto justify-content-center">
                                    <div className="col-sm-2 d-flex">
                                        <i className="fa-solid fa-headset fa-3x"></i>
                                    </div>
                                    <div className="col-sm-10">
                                        <h5 className="text-start">Call Us</h5>
                                        <p className="text-start">00923122003321</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="row d-flex mx-auto justify-content-center">
                                    <div className="col-sm-2 d-flex">
                                        <i className="fa-solid fa-envelope-open-text fa-3x"></i>
                                    </div>
                                    <div className="col-sm-10">
                                        <h5 className="text-start">Email Us</h5>
                                        <p className="text-start" onClick={handleEmailClick}>
                                            info@ziftexinternational.com
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className="container-fluid contact-form mb-5">
                <form id="contactform">
                    <h3 className="contact-title font-weight-bold">Drop Us a Message</h3>
                    <div className="row">
                        <div className="col-md-6">
                            <input type="hidden" name="companyName" value="Ziftex International" />
                            <div className="form-group">
                                <input type="text" name="txtName" className="form-control" id="name" placeholder="Name" required />
                            </div>
                            <div className="form-group">
                                <input type="email" name="txtEmail" className="form-control" id="email" placeholder="Email" required />
                            </div>
                            <div className="form-group">
                                <input type="text" name="txtCompany" className="form-control" placeholder="Company" required />
                            </div>
                            <div className="form-group">
                                <input type="tel" name="txtPhone" className="form-control" placeholder="Phone Number" required />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <textarea name="txtMsg" className="form-control message" id="message" rows="8" placeholder="Message" required></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <button type="submit" name="btnSubmit" className="form-button">Send Message</button>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default Contact;
