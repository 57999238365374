import MattressProtector1 from "../Images/mattress protector 2.png";
import MattressProtector2 from "../Images/Mattress Protector3.png";
import MattressProtector3 from "../Images/Mattress Protector4.png";
import MattressProtector4 from "../Images/Mattress Protector5.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function scrollToTop() {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }

export default function MattressProtector() {
    return (
        <section className="py-5 mt-5" id="about">
             <Helmet>
                <meta name="keywords" content="Waterproof Mattress Protector, Bed Pad, Wholesale Export" />
            </Helmet>
            <div className="container">
    <div className="row mt-5">
        <div className="col-sm-5 d-flex justify-content-center align-items-center items">
            <div className="row">
                <div className="col-12 mb-3">
                    <div className="zoom img-border">
                        <img className="object-fit-fill about-img" src={MattressProtector1} alt="Mattress Protector" />
                    </div>
                </div>
                <div className="col-12 mb-5">
                    <div id="myModal" className="modal">
                        <span className="close">&times;</span>
                        <div className="modal-content">
                            <img id="modalImg" src="" alt="Modal" />
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mx-auto mt-3">
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={MattressProtector2} alt="Mattress Protector" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={MattressProtector3} alt="Mattress Protector" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={MattressProtector4} alt="Mattress Protector" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-sm-7 d-flex justify-content-center flex-column mx-auto text-center align-items-center">
            <h3 className="font-weight-bold about-title">Mattress Protector</h3>
            <div className="sectionUnderline mx-auto"></div>
            <p className="mt-4 about-text">Ziftex International mattress protector shields your mattress from stains and bed bugs, ensuring longevity and comfort. Our premium standard and waterproofing provide unparalleled protection for a restful night's sleep.</p>
            <Link className="product-button mt-2" to="/contact" onClick={scrollToTop}>Inquire Now</Link>
        </div>
    </div>
</div>

            <Helmet>
                <script src="../js/Modal.js" />
            </Helmet>
        </section>
    );
}
