import ScrubSuits0 from "../Images/Scrub Suits4.png";
import ScrubSuits1 from "../Images/Scrub Suits3.png";
import ScrubSuits2 from "../Images/ScrubSuit2.jpeg";
import ScrubSuits3 from "../Images/Scrub Suits5.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function scrollToTop() {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }

export default function ScrubSuits() {
    return (
        <section className="py-5 mt-5" id="about">
             <Helmet>
                <meta name="keywords" content="Medical Scrub Suits, Men & Women, Breathable Fabric, High Quality" />
            </Helmet>
            <div className="container">
    <div className="row mt-5">
        <div className="col-sm-5 d-flex justify-content-center align-items-center items">
            <div className="row">
                <div className="col-12 mb-3">
                    <div className="zoom img-border">
                        <img className="object-fit-fill about-img" src={ScrubSuits0} alt="Scrub Suits" />
                    </div>
                </div>
                <div className="col-12 mb-5">
                    <div id="myModal" className="modal">
                        <span className="close">&times;</span>
                        <div className="modal-content">
                            <img id="modalImg" src="" alt="Modal" />
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mx-auto mt-3">
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={ScrubSuits1} alt="Scrub Suits" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={ScrubSuits2} alt="Scrub Suits" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={ScrubSuits3} alt="Scrub Suits" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-sm-7 d-flex justify-content-center flex-column mx-auto text-center align-items-center">
            <h3 className="font-weight-bold about-title">Scrub Suits</h3>
            <div className="sectionUnderline mx-auto"></div>
            <p className="mt-4 about-text">Ziftex International scrub suits offer freshness and hygiene, free from stains, odors, and wear and tear common with traditional reusable scrubs. Our scrubs promote infection control, demonstrating lower levels of bacterial air contamination compared to cotton or polyester scrubs.</p>
            <Link className="product-button mt-2" to="/contact" onClick={scrollToTop}>Inquire Now</Link>
        </div>
    </div>
</div>


            <Helmet>
                <script src="../js/Modal.js" />
            </Helmet>
        </section>
    );
}
