import LaundryBags1 from "../Images/Laundary Bags4.png";
import LaundryBags2 from "../Images/Laundary Bags3.png";
import LaundryBags3 from "../Images/Laundary Bags5.png";
import LaundryBags4 from "../Images/Laundary Bags6.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function scrollToTop() {
    window.scrollTo(0, 0); // Scroll to the top of the page
}

export default function LaundryBags() {
    return (
        <section className="py-5 mt-5" id="about">
            <Helmet>
                <meta name="keywords" content="Wholesale Laundry Bags, Mesh Laundry Bags, Manufacturer" />
            </Helmet>
            <div className="container">
    <div className="row mt-5">
        <div className="col-sm-5 d-flex justify-content-center align-items-center items">
            <div className="row">
                <div className="col-12 mb-3">
                    <div className="zoom img-border">
                        <img className="object-fit-fill about-img" src={LaundryBags1} alt="Laundry Bags" />
                    </div>
                </div>
                <div className="col-12 mb-5">
                    <div id="myModal" className="modal">
                        <span className="close">&times;</span>
                        <div className="modal-content">
                            <img id="modalImg" src="" alt="Modal" />
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mx-auto mt-3">
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={LaundryBags2} alt="Laundry Bags" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={LaundryBags3} alt="Laundry Bags" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={LaundryBags4} alt="Laundry Bags" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-sm-7 d-flex justify-content-center flex-column mx-auto text-center align-items-center">
            <h3 className="font-weight-bold about-title">Laundry Bags</h3>
            <div className="sectionUnderline mx-auto"></div>
            <p className="mt-4 about-text">Ziftex International laundry bags are crafted from durable, rip-resistant material with reinforced double stitching, ensuring longevity even through repeated washing and drying cycles. Featuring a secure drawstring slider closure, they neatly seal without the need for tying. With ample size to accommodate laundry hampers or baskets, our bags offer convenience by allowing you to simply pull them out instead of carrying the entire hamper.</p>
            <Link className="product-button mt-2" to="/contact" onClick={scrollToTop}>Inquire Now</Link>
        </div>
    </div>
</div>

            <Helmet>
                <script src="../js/Modal.js" />
            </Helmet>
        </section>
    );
}
