import IndustrialApron1 from "../Images/Industrial Apron1.png";
import IndustrialApron2 from "../Images/Industrial Apron2.png";
import IndustrialApron3 from "../Images/Industrial Apron3.png";
import IndustrialApron4 from "../Images/Industrial Apron4.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function scrollToTop() {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }

  export default function IndustrialApron() {
    return (
        <section className="py-5 mt-5" id="about">
            <Helmet>
                <meta name="keywords" content="Wholesale Industrial Aprons, Heavy Duty, Manufacturer & Exporter" />
            </Helmet>
            <div className="container">
    <div className="row mt-5">
        <div className="col-sm-5 d-flex justify-content-center align-items-center items">
            <div className="row">
                <div className="col-12 mb-3">
                    <div className="zoom img-border">
                        <img className="object-fit-fill about-img" src={IndustrialApron1} alt="Industrial Apron" />
                    </div>
                </div>
                <div className="col-12 mb-5">
                    <div id="myModal" className="modal">
                        <span className="close">&times;</span>
                        <div className="modal-content">
                            <img id="modalImg" src="" alt="Modal" />
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mx-auto mt-3">
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={IndustrialApron2} alt="Industrial Apron" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={IndustrialApron3} alt="Industrial Apron" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={IndustrialApron4} alt="Industrial Apron" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-sm-7 d-flex justify-content-center flex-column mx-auto text-center align-items-center">
            <h3 className="font-weight-bold about-title">Industrial Apron</h3>
            <div className="sectionUnderline mx-auto"></div>
            <p className="mt-4 about-text">Ziftex International produces high-quality, customized Industrial Safety Aprons, adhering to rigorous quality standards to ensure optimal protection and durability in industrial environments.</p>
            <Link className="product-button mt-2" to="/contact" onClick={scrollToTop}>Inquire Now</Link>
        </div>
    </div>
</div>

            <Helmet>
                <script src="../js/Modal.js" />
            </Helmet>
        </section>
    );
}
