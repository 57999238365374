import BabyBlanket1 from "../Images/Baby Blankets4.png";
import BabyBlanket2 from "../Images/Baby Blankets3.png";
import BabyBlanket3 from "../Images/baby blanket5.png";
import BabyBlanket4 from "../Images/baby blanket6.png";


import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function scrollToTop() {
    window.scrollTo(0, 0); // Scroll to the top of the page
}

export default function BabyBlankets() {
    return (
        <section className="py-5 mt-5" id="about">
            <Helmet>
                <meta name="keywords" content="Soft & Cozy Baby Blankets, Wholesale Receiving Blankets, Pakistan" />
            </Helmet>
            <div className="container">
                <div className="row mt-5">
                    <div className="col-sm-5 d-flex justify-content-center align-items-center items">
                        <div className="row">
                            <div className="col-12 mb-3">
                                <div className="zoom img-border">
                                    <img className="object-fit-fill about-img" src={BabyBlanket1} alt="" />
                                </div>
                            </div>
                            <div className="col-12 mb-5">
                                <div id="myModal" className="modal">
                                    <span className="close">&times;</span>
                                    <div className="modal-content">
                                        <img id="modalImg" src="" alt="Modal" />
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center mx-auto mt-3">
                                    <div className="col-3">
                                        <img className="gallery-img" src={BabyBlanket2} alt="Baby Blanket" />
                                    </div>
                                    <div className="col-3">
                                        <img className="gallery-img" src={BabyBlanket3} alt="Baby Blanket" />
                                    </div>
                                    <div className="col-3">
                                        <img className="gallery-img" src={BabyBlanket4} alt="Baby Blanket" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-7 d-flex justify-content-center flex-column mx-auto text-center align-items-center">
                        <h3 className="font-weight-bold about-title">Baby Blankets</h3>
                        <div className="sectionUnderline mx-auto"></div>
                        <p className="mt-4 about-text">Ziftex International baby blankets offer ultimate comfort for infants, crafted from durable cotton or cotton/poly blend fabric. Our blankets are machine-washable and meet stringent quality standards for infant safety and comfort, providing peace of mind for parents.</p>
                        <Link className="product-button mt-2" to="/contact" onClick={scrollToTop}>Inquire Now</Link>
                    </div>
                </div>
            </div>

            <Helmet>
                <script src="../js/Modal.js" />
            </Helmet>
        </section>
    );
}
