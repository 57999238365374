import WaterProofFlatPads1 from "../Images/Water Proof Flat Pads3.png";
import WaterProofFlatPads2 from "../Images/Water Proof Flat Pads4.png";
import WaterProofFlatPads3 from "../Images/Water Proof Flat Pads5.png";
import WaterProofFlatPads4 from "../Images/Water-Proof-Flat-Pads6.png";


import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function scrollToTop() {
    window.scrollTo(0, 0); // Scroll to the top of the page
}

export default function WaterProofFlatPads() {
    return (
        <section className="py-5 mt-5" id="about">
            <Helmet>
                <meta name="keywords" content="Waterproof Bed Pads, Mattress Protectors, Disposable & Reusable" />
            </Helmet>
            <div className="container">
                <div className="row mt-5">
                    <div className="col-sm-5 d-flex justify-content-center align-items-center items">
                        <div className="row">
                            <div className="col-12 mb-3">
                                <div className="zoom img-border">
                                    <img className="object-fit-fill about-img" src={WaterProofFlatPads1} alt="WaterProof Flat Pads" />
                                </div>
                            </div>
                            <div className="col-12 mb-5">
                                <div id="myModal" className="modal">
                                    <span className="close">&times;</span>
                                    <div className="modal-content">
                                        <img id="modalImg" src="" alt="Modal" />
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center mx-auto mt-3">
                                    <div className="col-3 gallery-col">
                                        <img className="gallery-img" src={WaterProofFlatPads2} alt="WaterProof Flat Pads" />
                                    </div>
                                    <div className="col-3 gallery-col">
                                        <img className="gallery-img" src={WaterProofFlatPads3} alt="WaterProof Flat Pads" />
                                    </div>
                                    <div className="col-3 gallery-col">
                                        <img className="gallery-img" src={WaterProofFlatPads4} alt="WaterProof Flat Pads" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-7 d-flex justify-content-center flex-column mx-auto text-center align-items-center">
                        <h3 className="font-weight-bold about-title">Water Proof Flat Pads</h3>
                        <div className="sectionUnderline mx-auto"></div>
                        <p className="mt-4 about-text">Ziftex International waterproof flat pads offer a soft and breathable surface, providing a comfortable waterproof barrier that blocks dust mites and potential allergens. Keep your mattress protected while enjoying a non-sweaty and cozy sleeping environment.</p>
                        <Link className="product-button mt-2" to="/contact" onClick={scrollToTop}>Inquire Now</Link>
                    </div>
                </div>
            </div>

            <Helmet>
                <script src="../js/Modal.js" />
            </Helmet>
        </section>
    );
}
