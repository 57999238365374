import PoolandBeachTowels1 from "../Images/pool towel.png";
import PoolandBeachTowels2 from "../Images/Pool And Beach Towels3.png";
import PoolandBeachTowels3 from "../Images/Pool And Beach Towels6.png";
import PoolandBeachTowels4 from "../Images/beach towel.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function scrollToTop() {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }

export default function PoolandBeachTowels() {
    return (
        <section className="py-5 mt-5" id="about">
             <Helmet>
                <meta name="keywords" content="Wholesale Pool & Beach Towels, High Absorbency, International Exporter" />
            </Helmet>
            <div className="container">
    <div className="row mt-5">
        <div className="col-sm-5 d-flex justify-content-center align-items-center items">
            <div className="row">
                <div className="col-12 mb-3">
                    <div className="zoom img-border">
                        <img className="object-fit-fill about-img" src={PoolandBeachTowels1} alt="Pool & Beach Towels" />
                    </div>
                </div>
                <div className="col-12 mb-5">
                    <div id="myModal" className="modal">
                        <span className="close">&times;</span>
                        <div className="modal-content">
                            <img id="modalImg" src="" alt="Modal" />
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mx-auto mt-3">
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={PoolandBeachTowels2} alt="Pool & Beach Towels" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={PoolandBeachTowels3} alt="Pool & Beach Towels" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={PoolandBeachTowels4} alt="Pool & Beach Towels" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-sm-7 d-flex justify-content-center flex-column mx-auto text-center align-items-center">
            <h3 className="font-weight-bold about-title">Pool & Beach Towels</h3>
            <div className="sectionUnderline mx-auto"></div>
            <p className="mt-4 about-text">Ziftex International pool and beach towels offer exceptional feel and absorbency. Our entire line of pool towels adheres to stringent quality standards, ensuring resilience against the effects of chlorinated water in pools, sunlight exposure, and beach conditions. Experience superior comfort and durability with Ziftex pool and beach towels.</p>
            <Link className="product-button mt-2" to="/contact" onClick={scrollToTop}>Inquire Now</Link>
        </div>
    </div>
</div>

            <Helmet>
                <script src="../js/Modal.js" />
            </Helmet>
        </section>
    );
}
