import DuvetandPillowCovers1 from "../Images/Duvet  Pillow Covers1.png";
import DuvetandPillowCovers2 from "../Images/Duvet  Pillow Covers2.png";
import DuvetandPillowCovers3 from "../Images/Duvet  Pillow Covers3.png";
import DuvetandPillowCovers4 from "../Images/Duvet  Pillow Covers4.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function scrollToTop() {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }

export default function DuvetandPillowCovers() {
    return (
        <section className="py-5 mt-5" id="about">
             <Helmet>
                <meta name="keywords" content="Wholesale Duvet Covers & Pillowcases, Manufacturer & Exporter" />
            </Helmet>
        
            <div className="container">
    <div className="row mt-5">
        <div className="col-sm-5 d-flex justify-content-center align-items-center items">
            <div className="row">
                <div className="col-12 mb-3">
                    <div className="zoom img-border">
                        <img className="object-fit-fill about-img" src={DuvetandPillowCovers1} alt="Duvet & Pillow Covers" />
                    </div>
                </div>
                <div className="col-12 mb-5">
                    <div id="myModal" className="modal">
                        <span className="close">&times;</span>
                        <div className="modal-content">
                            <img id="modalImg" src="" alt="Modal" />
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mx-auto mt-3">
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={DuvetandPillowCovers2} alt="Duvet & Pillow Covers" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={DuvetandPillowCovers3} alt="Duvet & Pillow Covers" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={DuvetandPillowCovers4} alt="Duvet & Pillow Covers" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-sm-7 d-flex justify-content-center flex-column mx-auto text-center align-items-center">
            <h3 className="font-weight-bold about-title">Duvet & Pillow Covers</h3>
            <div className="sectionUnderline mx-auto"></div>
            <p className="mt-4 about-text">Ziftex International luxury range of duvet covers, pillowcases, and bedding sets. Elevate your sleep experience with our exquisite silk pillowcases for an even more blissful night's rest. Sleep in style and comfort with Ziftex.</p>
            <Link className="product-button mt-2" to="/contact" onClick={scrollToTop}>Inquire Now</Link>
        </div>
    </div>
</div>

            <Helmet>
                <script src="../js/Modal.js" />
            </Helmet>
        </section>
    );
}
