import TShirts1 from "../Images/T-Shirt3.png";
import TShirts2 from "../Images/T-Shirt 1.png";
import TShirts3 from "../Images/T-Shirt 2.png";
import TShirts4 from "../Images/T-Shirt4.png";


import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function scrollToTop() {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }

export default function TShirts() {
    return (
        <section className="py-5 mt-5" id="about">
            <Helmet>
                <meta name="keywords" content="Wholesale T-Shirts, Custom Printing Available, Bulk Export" />
            </Helmet>
            <div className="container">
    <div className="row mt-5">
        <div className="col-sm-5 d-flex justify-content-center align-items-center items">
            <div className="row">
                <div className="col-12 mb-3">
                    <div className="zoom img-border">
                        <img className="object-fit-fill about-img" src={TShirts1} alt="T-Shirts" />
                    </div>
                </div>
                <div className="col-12 mb-5">
                    <div id="myModal" className="modal">
                        <span className="close">&times;</span>
                        <div className="modal-content">
                            <img id="modalImg" src="" alt="Modal" />
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mx-auto mt-3">
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={TShirts2} alt="T-Shirts" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={TShirts3} alt="T-Shirts" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={TShirts4} alt="T-Shirts" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-sm-7 d-flex justify-content-center flex-column mx-auto text-center align-items-center">
            <h3 className="font-weight-bold about-title">T-Shirts</h3>
            <div className="sectionUnderline mx-auto"></div>
            <p className="mt-4 about-text">Ziftex International specializes in designing and Outsourcing high-quality t-shirts, combining stylish designs with superior comfort and durability. Elevate your wardrobe with our premium T-shirt collection.</p>
            <Link className="product-button mt-2" to="/contact" onClick={scrollToTop}>Inquire Now</Link>
        </div>
    </div>
</div>

            <Helmet>
                <script src="../js/Modal.js" />
            </Helmet>
        </section>
    );
}