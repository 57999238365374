import { Helmet } from "react-helmet";
import "../css/counter.css";


const Counter = props => (
    <div className="container-fluid counter-container">
        <div className="row text-center">
            <div className="col-lg-4">
                <div className="counter">
                    <div className="side-by-side justify-content-center">
                        <h2
                            className="timer count-title count-number"
                            data-to="800"
                            data-speed="1500"
                        >  </h2>
                        <h2 className="count-title">k</h2>
                    </div>
                    <p className="count-text">KG Export per Month</p>
                </div>
                
            </div>
            <div className="col-lg-4">
                <div className="counter">
                    <div className="side-by-side justify-content-center"><h2 className="timer count-title count-number" data-to="20" data-speed="1500"> </h2><h2 className="count-title">+</h2> </div>

                    <p className="count-text"> Years of Experience</p>
                </div>
            </div>

            <div className="col-lg-4">
                <div className="counter">
                    <h2 className="timer count-title count-number" data-to="200" data-speed="1500"> </h2>
                    <p className="count-text">Hundred Shipment Completed</p>
                </div>
            </div>
        </div>
        <Helmet>
            <script src="../js/Counter.js" />
        </Helmet>
    </div>
);
export default Counter