
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import "../css/slider.css";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

// function scrollToTop() {
//     window.scrollTo(0, 0); // Scroll to the top of the page
// }


export default function Header() {
    return (
        <section>
            <div className="slider-container">
                <div className="slider">
                    <div className="box1 box">
                        <div className="bg"></div>
                        <div className="details">
                            <h1>Welcome To Ziftex International</h1>
                            <p>Dive into Our Exquisite Selection of Textile Offerings</p>
                            <Link className="product-button-1 mt-2" to="/contact">Inquire Now</Link>
                        </div>
                        <div className="illustration"><div className="inner"></div></div>
                    </div>

                    <div className="box2 box">
                        <div className="bg"></div>
                        <div className="details">
                            <h1>From Startups to Global Giants, We're Your Premier Source for Textile Excellence</h1>
                            <p>
                                In Search of a Top-tier Textile buying and outsourcing agency?
                            </p>
                            <Link className="product-button-1 mt-2" to="/contact">Inquire Now</Link>
                        </div>
                        <div className="illustration"><div className="inner"></div></div>
                    </div>

                    <div className="box3 box">
                        <div className="bg"></div>
                        <div className="details">
                            <h1>Textile Production</h1>
                            <p>
                                You Bring the Style, We Deliver the Textile
                            </p>
                            <Link className="product-button-1 mt-2" to="/contact">Inquire Now</Link>
                        </div>
                        <div className="illustration"><div className="inner"></div></div>
                    </div>

                    <div className="box4 box">
                        <div className="bg"></div>
                        <div className="details">
                            <h1>Crafting Excellence: Your Textile Partner</h1>
                            <p>
                                Elevate Your Brand with Our Textile Expertise
                            </p>
                            <Link className="product-button-1 mt-2" to="/contact">Inquire Now</Link>
                        </div>
                        <div className="illustration"><div className="inner"></div></div>
                    </div>

                    <div className="box5 box">
                        <div className="bg"></div>
                        <div className="details">
                            <h1>Transform Your Textile Vision</h1>
                            <p>
                                Designing Dreams, Stitching Success
                            </p>
                            <Link className="product-button-1 mt-2" to="/contact">Inquire Now</Link>
                        </div>
                        <div className="illustration"><div className="inner"></div></div>
                    </div>
                </div>

                <svg xmlns="http://www.w3.org/2000/svg" className="prev" width="56.898" height="91" viewBox="0 0 56.898 91"><path d="M45.5,0,91,56.9,48.452,24.068,0,56.9Z" transform="translate(0 91) rotate(-90)" fill="#fff" /></svg>
                <svg xmlns="http://www.w3.org/2000/svg" className="next" width="56.898" height="91" viewBox="0 0 56.898 91"><path d="M45.5,0,91,56.9,48.452,24.068,0,56.9Z" transform="translate(56.898) rotate(90)" fill="#fff" /></svg>
                <div className="trail">
                    <div className="box1 trailnum">1</div>
                    <div className="box2 trailnum">2</div>
                    <div className="box3 trailnum">3</div>
                    <div className="box4 trailnum">4</div>
                    <div className="box5 trailnum">5</div>
                </div>
                <Helmet>
                    <script src="../js/script.js" />
                </Helmet>
            </div>

        </section>
    );
}
