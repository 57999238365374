import GlassTowels1 from "../Images/Glass Towels4.png";
import GlassTowels2 from "../Images/Glass Towels3.png";
import GlassTowels3 from "../Images/glass towel1.png";
import GlassTowels4 from "../Images/Glass Towels6.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function scrollToTop() {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }

export default function GlassTowels() {
    return (
        <section className="py-5 mt-5" id="about">
             <Helmet>
                <meta name="keywords" content="Wholesale Glass Towels, Lint-Free, Manufacturer & Exporter" />
            </Helmet>
            <div className="container">
    <div className="row mt-5">
        <div className="col-sm-5 d-flex justify-content-center align-items-center items">
            <div className="row">
                <div className="col-12 mb-3">
                    <div className="zoom img-border">
                        <img className="object-fit-fill about-img" src={GlassTowels1} alt="Glass Towels" />
                    </div>
                </div>
                <div className="col-12 mb-5">
                    <div id="myModal" className="modal">
                        <span className="close">&times;</span>
                        <div className="modal-content">
                            <img id="modalImg" src="" alt="Modal" />
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mx-auto mt-3">
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={GlassTowels2} alt="Glass Towels" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={GlassTowels3} alt="Glass Towels" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={GlassTowels4} alt="Glass Towels" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-sm-7 d-flex justify-content-center flex-column mx-auto text-center align-items-center">
            <h3 className="font-weight-bold about-title">Glass Towels</h3>
            <div className="sectionUnderline mx-auto"></div>
            <p className="mt-4 about-text">Ziftex International glass towels feature a super tight weave, making them ideal for glass cleaning. Whether used damp or dry, they effectively remove dirt and grease, ensuring sparkling clean surfaces. Customize your size and weight to suit your needs.</p>
            <Link className="product-button mt-2" to="/contact" onClick={scrollToTop}>Inquire Now</Link>
        </div>
    </div>
</div>

            <Helmet>
                <script src="../js/Modal.js" />
            </Helmet>
        </section>
    );
}
