import React, { useState, useRef } from "react";
import { Outlet, Link } from "react-router-dom";
import Img from "../Images/ZI LOGO7 HD.png";

const Layout = () => {
    const [isOpen, setIsOpen] = useState(false);
    const collapseRef = useRef(null);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const closeNavbar = () => {
        setIsOpen(false);
        if (collapseRef.current) {
            collapseRef.current.classList.remove('show');
        }
    };

    function scrollToTop() {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }

    return (
        <>
            <header className="d-flex justify-content-center align-items-center">
                <nav className="navbar navbar-expand-lg fixed-top scrolling-navbar">
                    <Link to="/" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}><img src={Img} width="80px" alt="logo" className="LogoImg" /></Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        onClick={handleToggle}
                        aria-controls="basicExampleNav"
                        aria-expanded={isOpen}
                        aria-label="Toggle navigation"
                    >
                        <span className="fas fa-bars fa-lg"></span>
                    </button>
                    <div
                        className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`}
                        id="basicExampleNav"
                        ref={collapseRef}
                    >
                        <ul className="navbar-nav d-flex justify-content-center mx-auto align-items-center font-weight-bold">
                            <li className="nav-item active mr-5">
                                <Link to="/" className="nav-options text-decoration-none" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>Home</Link>
                            </li>
                            <li className="nav-item mr-5">
                                <Link to="/About-Us" className="nav-options text-decoration-none" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>About Us</Link>
                            </li>
                            <li className="nav-item dropdown mr-5">
                                <Link to="/HealthCare" className="nav-options text-decoration-none" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>
                                    Health Care <span className="fas fa-caret-down"></span>
                                </Link>
                                <ul className="dropdown-menu">
                                    <li><Link to="/BedLinensPillowCovers" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>BED LINENS PILLOW COVERS</Link></li>
                                    <li><Link to="/TerryTowels" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>TERRY TOWELS</Link></li>
                                    <li><Link to="/PatientGowns" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>PATIENT GOWNS</Link></li>
                                    <li><Link to="/ScrubSuits" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>SCRUB SUITS</Link></li>
                                    <li><Link to="/LabCoats" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>LAB COATS</Link></li>
                                    <li><Link to="/BathBlankets" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>BATH BLANKETS</Link></li>
                                    <li><Link to="/BabyBlankets" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>BABY BLANKETS</Link></li>
                                    <li><Link to="/ThermalBlankets" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>THERMAL BLANKETS</Link></li>
                                    <li><Link to="/SurgicalTowels" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>SURGICAL TOWELS (LINT FREE)</Link></li>
                                    <li><Link to="/WaterProofFlatPads" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>WATER PROOF FLAT PADS</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown mr-5">
                                <Link to="/Hospitality" className="nav-options text-decoration-none" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>
                                    Hospitality <span className="fas fa-caret-down"></span>
                                </Link>
                                <ul className="dropdown-menu">
                                    <li><Link to="/BeddingLinens" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>BEDDING LINENS</Link></li>
                                    <li><Link to="/Pillow" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>PILLOW</Link></li>
                                    <li><Link to="/PoolandBeachTowels" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>POOL & BEACH TOWELS</Link></li>
                                    <li><Link to="/DuvetandPillowCovers" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>DUVET & PILLOW COVERS</Link></li>
                                    <li><Link to="/BarMops" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>BARMOPS</Link></li>
                                    <li><Link to="/KitchenTowels" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>KITCHEN TOWELS</Link></li>
                                    <li><Link to="/LaundryBags" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>LAUNDRY BAGS</Link></li>
                                    <li><Link to="/DuvetComforters" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>DUVET COMFORTERS</Link></li>
                                    <li><Link to="/SalonandSpaTowels" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>SALON & SPA TOWELS</Link></li>
                                    <li><Link to="/TableCoversandNapkins" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>TABLE COVERS & NAPKINS</Link></li>
                                    <li><Link to="/MattressProtector" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>MATTRESS PROTECTOR</Link></li>
                                    <li><Link to="/TerryGrillPads" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>TERRY GRILL PADS</Link></li>
                                    <li><Link to="/GlassTowels" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>GLASS TOWELS</Link></li>
                                    <li><Link to="/BibApron" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>BIB APRON</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown mr-5">
                                <Link to="/Commercial" className="nav-options text-decoration-none" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>
                                    Commercial <span className="fas fa-caret-down"></span>
                                </Link>
                                <ul className="dropdown-menu">
                                    <li><Link to="/CanvasCloth" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>CANVAS CLOTH</Link></li>
                                    <li><Link to="/ShopTowels" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>SHOP TOWELS</Link></li>
                                    <li><Link to="/YellowDusterandMicroFiber" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>YELLOW DUSTER & MICRO FIBER</Link></li>
                                    <li><Link to="/FenderCover" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>FENDER COVER</Link></li>
                                    <li><Link to="/IndustrialApron" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>INDUSTRIAL APRON</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown mr-5">
                                <Link to="/Bedandbath" className="nav-options text-decoration-none" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>
                                    Bed & Bath <span className="fas fa-caret-down"></span>
                                </Link>
                                <ul className="dropdown-menu">
                                    <li><Link to="/Towels" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>TOWELS</Link></li>
                                    <li><Link to="/Bathrobes" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>BATHROBES</Link></li>
                                    <li><Link to="/Bedsheets" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>BEDSHEETS</Link></li>
                                    <li><Link to="/PillowCovers" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>PILLOW COVERS</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown mr-5">
                                <Link to="/Garments" className="nav-options text-decoration-none" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>
                                    Garments <span className="fas fa-caret-down"></span>
                                </Link>
                                <ul className="dropdown-menu">
                                    <li><Link to="/TShirts" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>T-SHIRTS</Link></li>
                                    <li><Link to="/ShortsandTrousers" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>SHORTS & TROUSERS</Link></li>
                                    <li><Link to="/DenimJeans" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>DENIM JEANS</Link></li>
                                    <li><Link to="/DenimJackets" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>DENIM JACKETS</Link></li>
                                    <li><Link to="/NightWears" className="dropdown-item" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>NIGHT WEARS</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item mr-5">
                                <Link to="/contact" className="nav-options text-decoration-none" onClick={(e) => {
                                        closeNavbar();
                                        scrollToTop();
                                    }}>Contact Us</Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
            <Outlet />
        </>
    );
};

export default Layout;
