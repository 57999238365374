import patientgowns from "../Images/PatientGowns.png";
import patientgowns1 from "../Images/Patient Gowns4.png";
import patientgowns2 from "../Images/Patient Gowns5.png";
import patientgowns3 from "../Images/Patient Gowns6.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function scrollToTop() {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }

export default function PatientGowns() {
    return (
        <section className="py-5 mt-5" id="about">
             <Helmet>
                <meta name="keywords" content="Wholesale Patient Gowns, Disposable Medical Gowns, Pakistan Supplier, Sterile & Non-Sterile, Breathable Fabric" />
            </Helmet>
            <div className="container">
    <div className="row mt-5">
        <div className="col-sm-5 d-flex justify-content-center align-items-center items">
            <div className="row">
                <div className="col-12 mb-3">
                    <div className="zoom img-border">
                        <img className="object-fit-fill about-img" src={patientgowns} alt="Patient Gowns" />
                    </div>
                </div>
                <div className="col-12 mb-5">
                    <div id="myModal" className="modal">
                        <span className="close">&times;</span>
                        <div className="modal-content">
                            <img id="modalImg" src="" alt="Modal" />
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mx-auto mt-3">
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={patientgowns1} alt="Patient Gowns" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={patientgowns2} alt="Patient Gowns" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={patientgowns3} alt="Patient Gowns" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-sm-7 d-flex justify-content-center flex-column mx-auto text-center align-items-center">
            <h3 className="font-weight-bold about-title">Patient Gowns</h3>
            <div className="sectionUnderline mx-auto"></div>
            <p className="mt-4 about-text">Ziftex International patient gowns prioritize comfort for patients, functionality for medical staff, and ease of processing for laundry. Ziftex offers a wide selection of patient gowns.</p>
            <Link className="product-button mt-2" to="/contact" onClick={scrollToTop}>Inquire Now</Link>
        </div>
    </div>
</div>

            <Helmet>
                <script src="../js/Modal.js" />
            </Helmet>
        </section>
    );
}
