import "../css/Projects.css";
import blog1 from "../Images/Yellowdustervsmicrofibre blog.png";
import blog2 from "../Images/bathrobe blog.png";
import blog3 from "../Images/barmop blog.png";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";


function scrollToTop() {
  window.scrollTo(0, 0); // Scroll to the top of the page
}
export default function Blogs() {
  return (
    <section className="">
      <Helmet>
        <meta name="keywords" content="Ziftex International, Blogs, The Evolution of Cleaning, Yellow Dusters, Microfiber Cloths, Bar Mops, Elevating Cleaning Efficiency, Bathrobes, Everyday Luxury with Style, Comfort, Relaxation, Luxury, Versatile, Modern Lifestyles, Contemporary Culture, Enduring Popularity, Spa, Wardrobe Staples, Loungewear, Outerwear, Terry Cloth, Silk Robe, Self-care, Mindfulness, Wellness-focused Society, Status, Luxury Item, Sustainability, Cleaning, Efficiency, Innovation, Robustness" />
      </Helmet>
      <div className="container">
        <div className="section-title-area text-center p-4">
          <h6 className="subtitle">Blogs</h6>
          <h2 className="font-weight-bold">OUR BLOGS</h2>
          <div className="sectionUnderline-feature mt-3 mx-auto"></div>
        </div>
        <div className="row p-4">
          <div className="col-sm-4 mb-4">
            <div className="card blog-card">
              <div className="position-relative">
                <img className="w-100" src={blog1} alt="Card cap" />
              </div>
            <div className="card-body blog-body">
                <h5 className="card-title font-weight-bold">The Evolution of Cleaning
                </h5>
               
                <hr />
                <p className="card-text mt-4">In the realm of cleaning, two stalwarts have long battled for supremacy: the humble Yellow Duster and the modern marvel, the Microfiber Cloth. Both... </p>
              </div>

              <div className="card-footer">
                <div className="media align-items-center">
                  <div className="media-body"> <Link className="read-more" to="/blog1" onClick={scrollToTop}>Read More</Link></div>

                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4 mb-4">
            <div className="card blog-card">
              <div className="position-relative">
                <img className="w-100" src={blog3} alt="Card cap" />
              </div>
              <div className="card-body blog-body">

                <h5 className="card-title font-weight-bold">Elevating Cleaning Efficiency</h5>
               
                <hr />
                <p className="card-text mt-4">In today's world saturated with cleaning products promising innovation and efficiency, the unassuming bar mop often goes unnoticed. Yet, this humble...</p>
              </div>
              <div className="card-footer">

                <div className="media align-items-center">

                  <div className="media-body"> <Link className="read-more" to="/blog2" onClick={scrollToTop}>Read More</Link></div>

                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4 mb-4">
            <div className="card blog-card">
              <div className="position-relative">
                <img className="w-100" src={blog2} alt="Card cap" />
              </div>
              <div className="card-body blog-body">
                <h5 className="card-title font-weight-bold">Elevating Everyday Luxury</h5>
             
                <hr />
                <p className="card-text mt-4">In a world where comfort is king, bathrobes stand as a timeless symbol of relaxation and luxury. Beyond their traditional role as post-shower attire, bath robes have...</p>
              </div>
              <div className="card-footer">
                <div className="media align-items-center">
                  <div className="media-body"> <Link className="read-more" to="/blog3" onClick={scrollToTop}>Read More</Link></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>





    </section>
  )



};

