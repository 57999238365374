import ShopTowel1 from "../Images/Shop Towel5.png";
import ShopTowel2 from "../Images/SHOP TOWEL1.png";
import ShopTowel3 from "../Images/Shop Towel4.png";
import ShopTowel4 from "../Images/Shop Towel6.png";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function scrollToTop() {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }

export default function ShopTowels() {
    return (
        <section className="py-5 mt-5" id="about">
             <Helmet>
                <meta name="keywords" content="Wholesale Shop Towels, Absorbent & Lint-Free, International Trade" />
            </Helmet>
            <div className="container">
    <div className="row mt-5">
        <div className="col-sm-5 d-flex justify-content-center align-items-center items">
            <div className="row">
                <div className="col-12 mb-3">
                    <div className="zoom img-border">
                        <img className="object-fit-fill about-img" src={ShopTowel1} alt="Shop Towels" />
                    </div>
                </div>
                <div className="col-12 mb-5">
                    <div id="myModal" className="modal">
                        <span className="close">&times;</span>
                        <div className="modal-content">
                            <img id="modalImg" src="" alt="Modal" />
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mx-auto mt-3">
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={ShopTowel2} alt="Shop Towels" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={ShopTowel3} alt="Shop Towels" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={ShopTowel4} alt="Shop Towels" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-sm-7 d-flex justify-content-center flex-column mx-auto text-center align-items-center">
            <h3 className="font-weight-bold about-title">Shop Towels</h3>
            <div className="sectionUnderline mx-auto"></div>
            <p className="mt-4 about-text">Ziftex International Shop Towels are rugged and durable, designed to withstand heavy-duty use for long-lasting performance. Ideal for a variety of applications, they are the perfect choice for demanding tasks.</p>
            <Link className="product-button mt-2" to="/contact" onClick={scrollToTop}>Inquire Now</Link>
        </div>
    </div>
</div>

            <Helmet>
                <script src="../js/Modal.js" />
            </Helmet>
        </section>
    );
}